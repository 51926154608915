import { styled } from '@aether/styles'
import { FC, Fragment } from 'react'
import { Media } from '@aether/components'
import { SiteConfiguration } from '@aether/models'
import { NoFiltersInfoText } from './NoFiltersInfoText'
import { ResolvedFilter } from '@aether/collection'
import { UpdateFiltersParamsFnType } from '../useCollectionSearchParams'

const Root = styled('div', {
  paddingBottom: '$48',
  display: 'grid',
  columnGap: '$12',
  rowGap: '$28',
  gridTemplateColumns: 'repeat(3, 1fr)',
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

const ColorPicker = styled('label', {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  $aetherFont: 'ui02',
  gap: '$20',
  border: '1px',
  margin: '$4',
  borderRadius: '$r2',
  variants: {
    isDisabled: {
      true: {
        opacity: 0.4,
      },
      false: {
        cursor: 'pointer',
      },
    },
  },
})

const SwatchWrap = styled('div', {
  width: 25,
  height: 25,
  borderRadius: '$rMax',
  '-webkit-transform': 'translateZ(0)',
  overflow: 'hidden',
  border: '1px solid transparent',
  variants: {
    stroke: {
      true: {
        border: '1px solid $gray_light_second',
      },
      false: {
        border: '1px solid transparent',
      },
    },
    selected: {
      true: {
        outline: '1px solid $black',
        outlineOffset: '2px',
      },
    },
  },
})

const HTML_ID_PREFIX = 'color_group_filter'

type Props = {
  className?: string
  onFilterChange: UpdateFiltersParamsFnType
  siteConfiguration?: SiteConfiguration
  filters: ResolvedFilter[]
  selectedFilters: string[]
}

const getColorGroup = (
  siteConfiguration: SiteConfiguration | undefined,
  value: string,
) => siteConfiguration?.colorGroupsMap?.find((obj) => obj.shopifyId === value)

export const ColorGroupFilter: FC<Props> = ({
  className,
  onFilterChange,
  siteConfiguration,
  selectedFilters,
  filters,
}) => {
  if (!filters?.length) {
    return <NoFiltersInfoText />
  }
  const queryKey = filters[0].searchParamName

  return (
    <Root className={className}>
      {filters.map(({ label, value, count }) => {
        const colorGroup = getColorGroup(siteConfiguration, label)
        const isSelected = selectedFilters?.includes(value)
        const HTML_ID = `${HTML_ID_PREFIX}_${value}`
        const isDisabled = Boolean(count === 0)
        if (!colorGroup) {
          return null
        }

        return (
          <Fragment key={value}>
            <InputRadio
              type="checkbox"
              id={HTML_ID}
              value={label}
              onClick={() => onFilterChange(queryKey, value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilterChange(queryKey, value)
                }
              }}
              checked={isSelected}
              disabled={isDisabled}
            />
            <ColorPicker htmlFor={HTML_ID} isDisabled={isDisabled}>
              <SwatchWrap selected={isSelected} stroke={colorGroup.withStroke}>
                <Media
                  {...colorGroup.image}
                  layout="fill"
                  sizes={{ default: '25px' }}
                />
              </SwatchWrap>
              <div> {`${colorGroup.label}`}</div>
            </ColorPicker>
          </Fragment>
        )
      })}
    </Root>
  )
}
